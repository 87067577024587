import React from "react";
import Grid from "@material-ui/core/Grid";
import PageTemplate from "../components/PageTemplate";
import "./../css/AboutUsPage.css";

export default function AboutUsPage() {
  return (
    <PageTemplate>
      <Grid container className="about-us-title">
        Kas mes?
      </Grid>
      <Grid container className="about-us-text">
        Mes esame šeimos įmonė, kuri veterinarijos srityje dirba jau daugiau
        kaip du dešimtmečius. Mūsų įmonės tikslas yra dirbti su savo srities
        lyderiais, todėl atstovaujame Turkijos pašarų gamybos lyderį Royal Ilac,
        vieną didžiausių rytų Vokietijos mineralinių pašarų gamybos įmonių Sn -
        neurrupin, bei lenkijos įmonę Vittra. Todėl galime pasiūlyti tiek aukštą
        kokybę, tiek žemas kainas.
      </Grid>
      <Grid container className="about-us-pictures">
        <Grid container className="picturePath1 about-us-picture"></Grid>
        <Grid container className="picturePath2 about-us-picture"></Grid>
      </Grid>
    </PageTemplate>
  );
}
