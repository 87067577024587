import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./../css/ProductsPage.css";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import RemoteCallComponent from "./../utils/RemoteCallComponent";
import { categoryService } from "../services/CategoryService";

function CowLogo() {
  return (
    <img
      src="/cow_logo_2.svg"
      alt=""
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    ></img>
  );
}

function FilterButton({ label, code, isSelected }) {
  const history = useHistory();
  const [showUnderline, setShowUnderline] = useState(false);
  const onClick = () => {
    if (code == null) {
      history.push("/products");
    } else {
      history.push("/products/" + code);
    }
  }
  return (
    <Grid container
      onClick={isSelected ? undefined : onClick}
      className="filter-button"
      style={{ cursor: isSelected ? "default" : "pointer" }}
      onMouseEnter={() => setShowUnderline(true)}
      onMouseLeave={() => setShowUnderline(false)}
    >
      <Grid container style={{ flexDirection: "row", flexWrap: "nowrap", backgroundColor: '#CCB09A', borderRadius: '10px' }}>
        <Grid container style={{ width: "30px", paddingRight: "5px", opacity: showUnderline || isSelected ? 1 : 0, transition: "400ms" }}>
          <CowLogo />
        </Grid>
        <Grid container>
          <Grid
            container
            className={isSelected ? "filter-button--label filter-button--label-clicked" : "filter-button--label"}
          >
            {label}
          </Grid>
          <Grid container className="filter-button--underline-placeholder">
            {!isSelected &&
              <Grid container
                className="filter-button--underline"
                style={{ width: showUnderline ? "100%" : "0" }}
              />
            }
          </Grid>
        </Grid>
        <Grid container style={{ width: "30px", paddingLeft: "5px", opacity: showUnderline || isSelected ? 1 : 0, transition: "400ms" }}>
          <CowLogo />
        </Grid>
      </Grid>
    </Grid>
  );
}

function FilterButtonContainer({ categories, selectedCategory }) {
  return (
    <Grid>
      <FilterButton
        code={undefined}
        label="Visi produktai"
        isSelected={selectedCategory === undefined}
      />
      {categories.map((category) => (
        <FilterButton
          key={category.id}
          code={category.code}
          label={category.name}
          isSelected={selectedCategory === category.code}
        />
      ))}
    </Grid>
  );
}

export default function Filter() {
  const { categoryCode } = useParams();
  const [state, setState] = useState({
    loading: true,
  });
  useEffect(() => {
    categoryService.findAll(setState);
  }, []);
  const Container = RemoteCallComponent(() => {
    return (
      <FilterButtonContainer
        categories={state.categories}
        selectedCategory={categoryCode}
      />
    );
  });
  return (
    <Grid container className="filter">
      <Container isLoading={state.loading} isError={state.error} />
    </Grid>
  );
}
