import React from "react";
import Grid from "@material-ui/core/Grid";

export default function ErrorMessage() {
  return (
    <Grid container style={{ justifyContent: "center" }}>
      <h3>Serverio klaida :(</h3>
    </Grid>
  );
}
