const API_URL = process.env.REACT_APP_API_URL;

export const categoryService = {
    findAll,
};

function findAll(setState) {
    fetch(`${API_URL}/categories`)
        .then((response) => response.json())
        .then((categories) => {
            setState({
                categories,
                loading: false,
                error: false,
            });
        });
}
