import React from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from '../components/ErrorMessage';

function RemoteCallComponent(Component) {
  return function StateComponent({ isLoading, isError, ...props }) {
    if (isLoading) {
      return <LoadingSpinner />;
    } else if (isError) {
      return <ErrorMessage />;
    } else {
      return <Component {...props} />;
    }
  };
}

export default RemoteCallComponent;
