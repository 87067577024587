import React from "react";
import Grid from "@material-ui/core/Grid";
import HeaderPanel from "./../components/HeaderPanel";
import MobileHeader from "./../components/MobileHeader";
import { useMobile } from "./../App";
import Footer from "./Footer";

function PageTemplate({
  children,
  contentAboveHeader,
  pageClassName = "",
  showFooter = true,
}) {
  const isMobile = useMobile();
  return (
    <Grid container className="page-template">
      <Grid
        container
        className={pageClassName}
        style={{ flexDirection: "column" }}
      >
        {!isMobile && contentAboveHeader}
        {isMobile ? <MobileHeader /> : <HeaderPanel />}
        <Grid container style={{ flex: "1", flexDirection: "column" }}>
          {children}
        </Grid>
        {/* {showFooter && <Footer />} */}
      </Grid>
    </Grid>
  );
}

export default PageTemplate;
