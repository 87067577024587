import { productAPI } from "../services/ProductAPI";

export function fetchProductById(id, setState) {
  productAPI
    .getOne({ id: id })
    .then((data) => {
      setState({ product: data, loading: false });
    })
    .catch(() => setState({ error: true, loading: false }));
}

export function updateLoading(setState, isLoading) {
  setState((prevState) => ({
    ...prevState,
    loading: isLoading,
  }));
}
