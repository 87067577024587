import React from "react";
import PageTemplate from "../components/PageTemplate";
import Grid from "@material-ui/core/Grid";
import ContactsForm from "../components/ContactsForm";
import "../css/ContactsPage.css";

function Title({ title }) {
  return (
    <Grid container className="contacts-page-section__title">
      {title}
    </Grid>
  );
}

function ContactsInfo({ infoLine1, infoLine2 }) {
  return (
    <Grid container className="contacts-info">
      <Grid
        container
        className="contacts-info__info-line contacts-info__info-line--bold"
      >
        {infoLine1}
      </Grid>
      <Grid container className="contacts-info__info-line">
        {infoLine2}
      </Grid>
    </Grid>
  );
}

function LeftSectionInfo() {
  return (
    <Grid container className="left-section-info">
      <ContactsInfo infoLine2="Direktorius Karolis Kerėžius" />
      <ContactsInfo
        infoLine1="Telefonas užsakymams:"
        infoLine2="+370 625 78533"
      />
      <ContactsInfo
        infoLine1="El. paštas:"
        infoLine2="info@balticnutrition.lt"
      />
      <ContactsInfo
        infoLine1="Adresas:"
        infoLine2="Dariaus ir Girėno g.7, Kelmė"
      />
    </Grid>
  );
}

function LeftSection() {
  return (
    <Grid
      container
      className="contacts-page-section contacts-page-section--margin-left"
    >
      <LeftSectionInfo />
    </Grid>
  );
}

function RightSection() {
  return (
    <Grid
      container
      className="contacts-page-section contacts-page-section--margin-right"
    >
      {/* <ContactsForm /> */}
    </Grid>
  );
}

function Content() {
  return (
    <Grid container style={{ flex: "1" }}>
      <Title title="Susisiekime!" />
      <Grid container className="sections">
        <LeftSection />
        <RightSection />
      </Grid>
    </Grid>
  );
}

function ContactsPage() {
  return (
    <PageTemplate>
      <Content />
    </PageTemplate>
  );
}

export default ContactsPage;
