import React from "react";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import ImageDisplayer from "./ImageDisplayer";

function Title({ name }) {
  return (
    <Grid container className="item-card--title">
      {name}
    </Grid>
  );
}

export default function ItemCard({ item }) {
  const history = useHistory();
  return (
    <Grid container className="item-card--container">
      <Grid
        className="item-card"
        container
        onClick={() => {
          history.push("/products/" + item.category.code + "/" + item.id);
        }}
      >
        <ImageDisplayer imageInfo={item.imageInfos[0]} />
        <Title name={item.name} />
      </Grid>
    </Grid>
  );
}
