import { Grid } from "@material-ui/core";
import React from "react";
import PageTemplate from "../components/PageTemplate";
import "./../css/NewsPage.css";

function NewsPage() {
  return (
    <PageTemplate>
      <Grid container className="news-page-content">
        Informacija ruošiama
      </Grid>
    </PageTemplate>
  );
}

export default NewsPage;
