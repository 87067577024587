import React, { useState, useEffect } from "react";
import "./css/App.css";
import "antd/dist/antd.css";
import { Switch, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";
import AboutUsPage from "./pages/AboutUsPage";
import ProductsPage from "./pages/ProductsPage";
import ContactsPage from "./pages/ContactsPage";
import SingleProductPage from "./pages/SingleProductPage";

export function useMobile() {
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    function update() {
      setIsMobile(window.innerWidth <= 870);
    }
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);
  return isMobile;
}

export function useScreenWidth() {
  const [width, setWidth] = useState(null);
  useEffect(() => {
    function update() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", update);
    update();
    return () => window.removeEventListener("resize", update);
  }, []);
  return width;
}

function App() {
  return (
    <Switch>
      <Route path="/" exact>
        <MainPage />
      </Route>
      <Route path="/news" exact>
        <NewsPage />
      </Route>
      <Route path="/aboutUs" exact>
        <AboutUsPage />
      </Route>
      <Route path="/products/:categoryCode?" exact>
        <ProductsPage />
      </Route>
      <Route path="/products/:categoryCode/:id" exact>
        <SingleProductPage />
      </Route>
      <Route path="/contacts" exact>
        <ContactsPage />
      </Route>
      <Route path="*" exact>
        <h2>404 not found</h2>
      </Route>
    </Switch>
  );
}

export default App;
