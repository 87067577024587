import React from "react";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import HeaderButton from "./../components/HeaderButton";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getHeaderMenuItems } from "./../data/ButtonData";

function MobileMenuPanel({ onCrossClick, headerMenuItems }) {
  return (
    <Grid className="mobile-menu-panel">
      <Grid
        className="mobile-menu-panel__close-button"
        onClick={onCrossClick}
      ></Grid>
      <Grid style={{ padding: "0 10%", textAlign: "center" }}>
        {headerMenuItems.map((headerMenuItem) => (
          <HeaderButton item={headerMenuItem} />
        ))}
      </Grid>
    </Grid>
  );
}

function BurgerButton({ onBurgerClick }) {
  return (
    <Grid
      container
      className="mobile-header"
      style={{
        position: "fixed",
      }}
      onClick={onBurgerClick}
    >
      <Grid
        container
        style={{ justifyContent: "center", alignContent: "center" }}
      >
        <MenuIcon htmlColor="#ebe3d9" />
      </Grid>
    </Grid>
  );
}

export default function MobileHeader() {
  const history = useHistory();
  const headerMenuItems = getHeaderMenuItems(history);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  let onBurgerClick = () => setShowMobileMenu(true);
  let onCrossClick = () => {
    setShowMobileMenu(false);
  };

  useEffect(() => {}, [showMobileMenu]);

  return (
    <Grid container className="mobile-header">
      {showMobileMenu ? (
        <MobileMenuPanel
          headerMenuItems={headerMenuItems}
          onCrossClick={onCrossClick}
        />
      ) : (
        <BurgerButton onBurgerClick={onBurgerClick} />
      )}
    </Grid>
  );
}
