import React from "react";

function findTrailingZeros(number) {
  const numberString = number.toString();
  const match = numberString.match(/[0]+$/);
  return match ? match[0].length : 0;
}

export default function LargeNumberDisplayer({ number, settings }) {
  if (
    settings.minNumberWithPower == null ||
    number < settings.minNumberWithPower
  ) {
    return number;
  }
  const trailingZeros = findTrailingZeros(number);
  if (trailingZeros === 0) {
    return number;
  }
  const power = number.toString().length - trailingZeros - 1 + trailingZeros;
  const value = number / Math.pow(10, power);
  const beginning = (
    <span>
      {value}
      <span
        style={{
          fontSize: "0.7em",
          verticalAlign: "baseline",
        }}
      >
        x
      </span>
    </span>
  );
  const ending = (
    <span>
      10
      <span style={{ fontSize: "0.6em", verticalAlign: "top" }}>{power}</span>
    </span>
  );
  return (
    <span>
      {value !== 1 && beginning}
      {ending}
    </span>
  );
}
