import React from "react";
import "./../css/MainPage.css";
import Grid from "@material-ui/core/Grid";
import PageTemplate from "../components/PageTemplate";

function ContentAboveHeader() {
  return (
    <Grid container className="main-page-title">
      Baltic Nutrition
    </Grid>
  );
}

function MainPage() {
  return (
    <PageTemplate
      pageClassName="main-page"
      contentAboveHeader={<ContentAboveHeader />}
      showFooter={false}
    >
      <Grid container className="main-page-photo"></Grid>
      <Grid container className="main-page-description">
        Jūsų šėrimo partneris!
      </Grid>
    </PageTemplate>
  );
}

export default MainPage;
