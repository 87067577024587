export function getHeaderMenuItems(history) {
  const headerNaujienosItem = {
    id: 1,
    label: "Naujienos",
    handleClick: () => {
      history.push("/news");
    },
  };
  const headerApieMusItem = {
    id: 2,
    label: "Apie Mus",
    handleClick: () => {
      history.push("/aboutUs");
    },
  };
  const headerLogoItem = {
    id: 3,
    source: "/cow_logo_2.svg",
    handleClick: () => {
      history.push("/");
    },
  };
  const headerProduktaiItem = {
    id: 4,
    label: "Produktai",
    handleClick: () => {
      history.push("/products");
    },
  };
  const headerKontaktaiItem = {
    id: 5,
    label: "Kontaktai",
    handleClick: () => {
      history.push("/contacts");
    },
  };
  return [
    headerProduktaiItem,
    headerNaujienosItem,
    headerLogoItem,
    headerApieMusItem,
    headerKontaktaiItem,
  ];
}
