import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../css/ProductsPage.css";
import Grid from "@material-ui/core/Grid";
import Filter from "../components/Filter";
import PageTemplate from "../components/PageTemplate";
import ItemCard from "../components/ItemCard";
import { updateLoading } from "./../utils/ProductPageUtils";
import RemoteCallComponent from "./../utils/RemoteCallComponent";
import { productAPI } from "../services/ProductAPI";
import Paginator from './../components/Paginator';
import * as Scroll from 'react-scroll';

const scroll = Scroll.animateScroll;

const initialState = {
  loading: true,
  products: [],
  error: false,
  // currentPage: 1,
  // pageSize: 24,
  // total: 0,
};

function Description() {
  return (
    <Grid container className="extra-info">
      Įmonės atstovaujami gamintojai iš Vokietijos, Turkijos ir Lenkijos gamina
      daug skirtingų produktų, kurių tik dalį rasite kataloge, todėl norėdami
      gauti daugiau informacijos prašome kreiptis į mus nurodytais kontaktais.
    </Grid>
  );
}

// function onPageChange(page, pageSize, setState, categoryCode) {
//   scroll.scrollToTop({ duration: 300 });
//   updateLoading(setState, true);
//   productAPI.getAll(categoryCode, page, pageSize).then((response) => {
//     setState((prevState) => ({
//       ...prevState,
//       products: response.products,
//       loading: false,
//       error: false,
//       total: response.total,
//       currentPage: page,
//     }));
//   });
// }

function ProductsContainer() {
  const { categoryCode } = useParams();
  const [state, setState] = useState(initialState);
  useEffect(() => {
    updateLoading(setState, true);
    productAPI.getAll(categoryCode, 1, 100).then((response) => {
      setState((prevState) => ({
        ...prevState,
        products: response.products,
        loading: false,
        error: false,
        //total: response.total,
        //currentPage: 1,
      }));
    });
  }, [categoryCode]);
  const ItemCardsComponent = () => {
    return (
      <>
        {state.products.map((product) => (
          <ItemCard item={product} key={product.id} />
        ))}
      </>
    );
  };
  const Container = RemoteCallComponent(ItemCardsComponent);
  return (
    <Grid>
      <Grid container className="content-panel-products">
        <Container isLoading={state.loading} isError={state.error} />
      </Grid>
      {/* <Paginator
        current={state.currentPage}
        pageSize={state.pageSize}
        total={state.total}
        hideOnSinglePage
        onChange={(page) => {
          onPageChange(page, state.pageSize, setState, categoryCode);
        }}
      /> */}
    </Grid>
  );
}

export default function ProductsPage() {
  return (
    <PageTemplate>
      <Grid container className="product-page-content">
        <Filter />
        <Grid className="product-container">
          <Description />
          <ProductsContainer />
        </Grid>
      </Grid>
    </PageTemplate>
  );
}
