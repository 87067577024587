import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';

export default function UnderlinedButton({ onClick, title, style }) {
    const [buttonEntered, setButtonEntered] = useState(false);
    return (
        <Grid container
            className="underlined-button"
            onClick={onClick}
            onMouseEnter={() => setButtonEntered(true)}
            onMouseLeave={() => setButtonEntered(false)}
            style={style}>
            <Grid>
                {title}
            </Grid>
            <Grid container className="underlined-button-placeholder">
                <Grid container
                    className="underlined-button--underline"
                    style={{ width: buttonEntered ? "60%" : "0" }}
                />
            </Grid>
        </Grid>

    );
}