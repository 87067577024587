import React, { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Modal from 'react-modal';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LargeNumberDisplayer from "./LargeNumberDisplayer";
import UnderlinedButton from "./UnderlinedButton";
import { useMobile } from "./../App";

function ModalContent({ ingredients, settings, onCloseClick }) {
    return (
        <Grid container>
            <Grid container onClick={onCloseClick} className="close-button-parent">
                <UnderlinedButton onClick={onCloseClick} title="Uždaryti" style={{ width: "200px" }} />
            </Grid>
            <Grid container style={{ padding: "0 10px 10px 10px" }}>
                <Grid container>
                    <Table>
                        <TableBody>
                            {ingredients.map((ingredient) => (
                                <TableRow key={ingredient.name}>
                                    <TableCell>
                                        {ingredient.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <LargeNumberDisplayer
                                            number={ingredient.amount}
                                            settings={settings}
                                        />
                                        {ingredient.unit}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default function IngredientsModal({ visible, settings, ingredients }) {
    const [modalIsOpen, setIsOpen] = useState(false);
    const openModal = () => { setIsOpen(true) };
    const closeModal = () => { setIsOpen(false) };
    const isMobile = useMobile();

    const customStyles = {
        content: {
            top: isMobile ? '0%' : '10%',
            left: isMobile ? '0%' : '10%',
            right: isMobile ? '0%' : '10%',
            bottom: isMobile ? '0%' : '10%',
            backgroundColor: "rgb(250, 235, 215)",
            border: "1px solid black",
            padding: 0
        },
        overlay: {
            backgroundColor: "rgba(230, 205, 182, 0.4)",
            zIndex: '2'
        }
    };


    return (
        visible && (
            <Grid container style={{ justifyContent: "center" }}>
                <UnderlinedButton onClick={openModal} title="Sudėtis" style={{ margin: "20px 0", width: "50%" }} />
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Produkto sudėtis"
                    style={customStyles}
                >
                    <ModalContent ingredients={ingredients} settings={settings} onCloseClick={closeModal} />
                </Modal>
            </Grid >
        )
    )
}