import React from "react";
import Grid from "@material-ui/core/Grid";
import { SideBySideMagnifier } from "react-image-magnifiers";

const API_URL = process.env.REACT_APP_API_URL;

export default function ImageDisplayer({ imageInfo, withMagnifier = false }) {
  if (imageInfo == null || imageInfo.guid == null) {
    return <></>;
  } else {
    return (
      <Grid container>
        {withMagnifier ? (
          <SideBySideMagnifier
            imageSrc={`${API_URL}/images/${imageInfo.guid}`}
            imageAlt="Nuotrauka nerasta"
            className="large-image-style"
            style={{
              width: "100%",
              overflow: "hidden",
            }}
            alwaysInPlace={true}
            largeImageSrc={`${API_URL}/images/${imageInfo.guid}?fullSize=true`}
          />
        ) : (
          <img
            src={`${API_URL}/images/${imageInfo.guid}`}
            alt="Nuotrauka nerasta"
            style={{ width: "100%" }}
          ></img>
        )}
      </Grid>
    );
  }
}
