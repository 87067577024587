import React from "react";
import Grid from "@material-ui/core/Grid";
import "./../css/Header.css";
import { useHistory } from "react-router-dom";
import HeaderButton from "./HeaderButton";
import { getHeaderMenuItems } from "./../data/ButtonData";

export default function HeaderPanel() {
  const history = useHistory();
  const headerMenuItems = getHeaderMenuItems(history);
  return (
    <Grid container className="header">
      {headerMenuItems.map((headerMenuItem) => (
        <HeaderButton item={headerMenuItem} key={headerMenuItem.id} />
      ))}
    </Grid>
  );
}
