import React, { useState, useEffect } from "react";
import PageTemplate from "../components/PageTemplate";
import Grid from "@material-ui/core/Grid";
import Filter from "../components/Filter";
import { useParams } from "react-router-dom";
import DropDown from "../components/DropDown";
import { fetchProductById } from "./../utils/ProductPageUtils";
import ErrorMessage from "../components/ErrorMessage";
import LoadingSpinner from "../components/LoadingSpinner";
import { useMobile } from "./../App";
import ImageDisplayer from "./../components/ImageDisplayer";
import IngredientsModal from "../components/IngredientsModal";

const API_URL = process.env.REACT_APP_API_URL;

function ProductTitle({ product }) {
  return (
    <Grid container className="item-page-section__title">
      {product.name}
    </Grid>
  );
}

function ImageSection({ product, settings }) {
  return (
    <Grid container className="single-product-image-section">
      <Grid container className="single-product-photo">
        <ImageDisplayer imageInfo={product.imageInfos[0]} withMagnifier />
      </Grid>
      <IngredientsModal
          visible={product.ingredients && product.ingredients.length > 0}
          settings={settings}
          ingredients={product.ingredients} />
    </Grid>
  );
}

function TextArea({ text }) {
  return (
    text && (
      <Grid
        container
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  );
}

function DetailsSection({ product }) {
  return (
    <Grid style={{ flex: 2 }}>
      <ProductTitle product={product} />
      <Grid style={{ padding: "0 10%" }}>
        <Grid container className="drop-down-info">
          <TextArea text={product.description} />
        </Grid>
        <DropDown
          title="Poveikis"
          content={<TextArea text={product.effect} />}
          visible={product.effect != null}
        />
        <DropDown
          title="Naudojimo būdas"
          content={<TextArea text={product.usage} />}
          visible={product.usage != null}
        />
      </Grid>
    </Grid>
  );
}

function ProductDetails({ product, settings }) {
  return (
    <Grid container className="single-product-details">
      <ImageSection product={product} settings={settings}/>
      <DetailsSection product={product} />
    </Grid>
  );
}

function ProductContainer({ settings }) {
  const { id } = useParams();
  const [state, setState] = useState({ loading: true });
  useEffect(() => {
    fetchProductById(id, setState);
  }, [id]);
  if (state.loading) {
    return <LoadingSpinner />;
  } else if (state.error) {
    return <ErrorMessage />;
  } else {
    return (
      <Grid container className="product-container">
        <ProductDetails product={state.product} settings={settings} />
      </Grid>
    );
  }
}

export default function SingleProductPage() {
  const isMobile = useMobile();
  const [settings, setSettings] = useState({});
  useEffect(() => {
    fetch(`${API_URL}/settings`)
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
      });
  }, []);
  return (
    <PageTemplate>
      <Grid container style={{ flex: "1" }}>
        {!isMobile && <Filter />}
        <ProductContainer settings={settings} />
      </Grid>
    </PageTemplate>
  );
}
