import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";

function LogoButton({ item }) {
  const [hovered, setHovered] = useState(false);
  return (
    <Grid className="header-button--logo"
      onClick={item.handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <img alt="" src={item.source} style={{ height: "50px" }}
        className={hovered ? "header-button--logo-hover" : ""}></img>
    </Grid>
  );
}

function TextButton({ item }) {
  const [showUnderline, setShowUnderline] = useState(false);
  return (
    <Grid style={{ padding: "10px", cursor: "pointer" }}
      onClick={item.handleClick}
      onMouseEnter={() => setShowUnderline(true)}
      onMouseLeave={() => setShowUnderline(false)}>
      <Grid className="header-button--label" >
        {item.label}
      </Grid>
      <Grid container className="header-button--underline-placeholder">
        <Grid container
          className="header-button--underline"
          style={{ width: showUnderline ? "100%" : "0" }}
        />
      </Grid>
    </Grid>
  );
}

export default function HeaderButton({ item }) {
  const isLogo = item.source != null;
  if (isLogo) {
    return <LogoButton item={item} />;
  } else {
    return <TextButton item={item} />;
  }
}
