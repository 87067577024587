const API_URL = process.env.REACT_APP_API_URL;

export const productAPI = {
  getAll,
  getOne,
};

function getAll(categoryCode, page, size) {
  if (categoryCode == null) {
    return fetch(
      `${API_URL}/products?page=${page - 1}&size=${size}&sort=id`
    ).then((response) => response.json());
  } else {
    return fetch(
      `${API_URL}/products?categoryCode=${categoryCode}&page=${
        page - 1
      }&size=${size}&sort=id`
    ).then((response) => response.json());
  }
}

function getOne({ id }) {
  return fetch(`${API_URL}/products/${id}`).then((response) => response.json());
}
