import React from "react";
import Grid from "@material-ui/core/Grid";
import ReactLoading from "react-loading";

export default function LoadingSpinner() {
  return (
    <Grid container style={{ justifyContent: "center" }}>
      <ReactLoading type="spin" color="#673F18" width="35px" />
    </Grid>
  );
}
