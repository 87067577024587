import React from "react";
import Grid from "@material-ui/core/Grid";

export default function DropDown({
  title,
  content,
  visible = true
}) {
  return (
    visible && (
      <Grid container>
        <Grid container className="drop-down">
          {title}
        </Grid>
        <Grid container className="drop-down-info">
          {content}
        </Grid>
      </Grid>
    )
  );
}
